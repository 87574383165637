<template>
  <div class="UserListContainer" v-track="{triggerType:'browse',currentUrl: $route.fullPath,behavior:'浏览用户列表页面',actionType:'进入页面'}">
    <!--查询区域start-->
    <div class="search-area">
      <div class="form-area">
      <el-form class="search-form" ref="search-form" :model="searchForm" :rules="searchFormRules" label-width="100px" >
        <el-form-item label="昵称：" prop="nickname">
          <el-input v-model="searchForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobilePhone">
          <el-input v-model="searchForm.mobilePhone"></el-input>
        </el-form-item>
        <el-form-item label="创建日期：">
          <el-date-picker
            v-model="searchForm.createTime"
            type="daterange"
            align="right"
            unlink-panels
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="searchForm.status">
            <el-option label="全部" value=""></el-option>
            <el-option label="已启用" value="1"></el-option>
            <el-option label="已停用" value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      </div>
      <div class="search-btn-area">
        <el-button type="primary" class="search-btn" @click="queryUserList" v-track="{triggerType:'click',currentUrl: $route.fullPath,behavior:'点击查询按钮',businessCode: 19,actionType:'xxx-click'}">查询</el-button>
        <el-button class="reset-btn" @click="resetBtn">重置</el-button>
      </div>
    </div>
    <!--查询区域end-->

    <!-- 表格区域start -->
    <div class="table-area">
      <div class="operate-btn">
        <el-button v-check-auth="['sys#user#add']" type="primary" class="add-btn" @click="addDialogOpen"><i class="el-icon-plus"></i>新增用户</el-button>
      </div>
      <el-table
        ref="resultTable"
        :data="tableList"
        tooltip-effect="dark"
        v-loading="tableLoading">
        <el-table-column
          prop="userId"
          label="用户编号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="account"
          label="用户账号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="用户昵称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="mobilePhone"
          label="手机"
          width="180">
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          width="180">
        </el-table-column>
        <el-table-column
          prop="createData"
          label="创建时间"
          width="180">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="180">
          <template v-slot="scope">
            <el-tag type="success" v-if="scope.row.status==='1'">已启用</el-tag>
            <el-tag type="info" v-if="scope.row.status==='0'">已停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          v-slot="scope"
          min-width="260">
          <template>
            <el-button type="text" class="edit-btn" @click="editDialogOpen(scope.row)">编辑</el-button>
            <el-button type="text" class="edit-btn" @click="resetPwd(scope.row.userId)">重置密码</el-button>
            <el-button type="text" class="edit-btn" @click="getUserDetail(scope.row.userId)">详情</el-button>
            <el-button type="text" class="del-btn" @click="delUser(scope.row.userId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 表格区域end -->

    <!--新增用户弹窗start-->
    <el-dialog title="新增用户" :visible.sync="addUserDialogFlag" width="600px">
      <el-form ref="add-user-form" label-position="right" :model="addUserForm" label-width="120px" :rules="addUserFormRules">
        <el-form-item label="用户账号：" prop="account">
          <el-input v-model="addUserForm.account"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称：" prop="nickname">
          <el-input v-model="addUserForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobilePhone">
          <el-input v-model="addUserForm.mobilePhone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="addUserForm.email"></el-input>
        </el-form-item>
        <el-form-item label="角色：" prop="role">
          <el-select v-model="addUserForm.role" placeholder="请为此用户分配角色">
            <el-option v-for="(item,index) in roleList" :label="item.roleName" :value="item.roleId" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-switch
            v-model="addUserForm.status"
            active-color="#13ce66"
            inactive-color="#87939a"
            active-value="1"
            inactive-value="0">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addUserDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </div>
    </el-dialog>
    <!--新增用户弹窗end-->

    <!--编辑用户弹窗start-->
    <el-dialog title="编辑用户信息" :visible.sync="editUserDialogFlag" width="600px">
      <el-form ref="edit-user-form" label-position="right" :model="editUserForm" label-width="120px" :rules="addUserFormRules">
        <el-form-item label="用户账号：" prop="account">
          <el-input v-model="editUserForm.account"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称：" prop="nickname">
          <el-input v-model="editUserForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobilePhone">
          <el-input v-model="editUserForm.mobilePhone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="editUserForm.email"></el-input>
        </el-form-item>
        <el-form-item label="角色：" prop="role">
          <el-select v-model="editUserForm.role">
            <el-option v-for="(item,index) in roleList" :label="item.roleName" :value="item.roleId" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-switch
            v-model="editUserForm.status"
            active-color="#13ce66"
            inactive-color="#87939a"
            active-value="1"
            inactive-value="0">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editUserDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </div>
    </el-dialog>
    <!--编辑用户弹窗end-->

    <!--用户详情弹窗start-->
    <el-dialog title="用户详情" :visible.sync="detailUserDialogFlag" width="600px">
      <el-descriptions>
        <el-descriptions-item label="用户编号">{{ detailForm.userId }}</el-descriptions-item>
        <el-descriptions-item label="登录账号">{{detailForm.account}}</el-descriptions-item>
        <el-descriptions-item label="昵称">{{detailForm.nickname}}</el-descriptions-item>
        <el-descriptions-item label="手机">{{detailForm.mobilePhone}}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{detailForm.email}}</el-descriptions-item>
        <el-descriptions-item label="角色">{{detailForm.role}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{detailForm.createData}}</el-descriptions-item>
        <el-descriptions-item label="状态">
            <el-tag size="small" type="success" v-if="detailForm.status==='1'">已启用</el-tag>
            <el-tag size="small" type="info" v-if="detailForm.status==='0'">已停用</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailUserDialogFlag = false">关闭</el-button>
      </div>
    </el-dialog>
    <!--用户详情弹窗end-->
  </div>
</template>

<script>
import textValidator from '@/util/validate/text-validator'
export default {
  name: 'dictionary',
  data() {
    const checkNickNameForSearch = (rules, value, callback) => {
      textValidator.checkTextName(false, '昵称', rules, value, callback, 2, 20)
    }
    const checkMobilePhoneForSearch = (rules, value, callback) => {
      textValidator.checkPhoneNumber(false, '手机号', rules, value, callback, 0, 11)
    }

    const checkNickName = (rules, value, callback) => {
      textValidator.checkTextName(true, '昵称', rules, value, callback, 2, 20)
    }
    const checkMobilePhone = (rules, value, callback) => {
      textValidator.checkPhoneNumber(true, '手机号', rules, value, callback, 0, 11)
    }

    const checkAccount = (rules, value, callback) => {
      textValidator.checkAccount(true, '账号', rules, value, callback, 8, 20)
    }
    const checkEmail = (rules, value, callback) => {
      textValidator.checkEmail(false, '邮箱', rules, value, callback, 0, 32)
    }

    return {
      // 日期选择快捷键
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      // 查询表单
      searchForm: {
        nickname: '',
        mobilePhone: '',
        createTime: [],
        status: ''
      },
      // 查询表单校验规则
      searchFormRules: {
        nickname: [
          {
            validator: checkNickNameForSearch,
            trigger: 'change'
          }
        ],
        mobilePhone: [
          {
            validator: checkMobilePhoneForSearch,
            trigger: 'change'
          }
        ]
      },
      // 表格加载
      tableLoading: '',
      tableList: [],

      // --- 弹窗区域 ---
      // 角色列表
      roleList: [
        {
          roleName: '开发者',
          roleId: '001'
        },
        {
          roleName: '系统管理员',
          roleId: '002'
        },
        {
          roleName: '普通用户',
          roleId: '003'
        }
      ],
      // 新增弹窗
      addUserDialogFlag: false,
      // 新增表单
      addUserForm: {
        account: '',
        nickname: '',
        mobilePhone: '',
        email: '',
        role: '',
        status: '1'
      },
      // 表单校验
      addUserFormRules: {
        account: [
          {
            required: true,
            validator: checkAccount,
            trigger: 'change'
          }
        ],
        nickname: [
          {
            required: true,
            validator: checkNickName,
            trigger: 'change'
          }
        ],
        mobilePhone: [
          {
            required: true,
            validator: checkMobilePhone,
            trigger: 'change'
          }
        ],
        email: [
          {
            validator: checkEmail,
            trigger: 'change'
          }
        ],
        role: [
          {
            required: true,
            message: '请为此用户分配角色',
            trigger: 'change'
          }
        ]
      },
      // 编辑弹窗
      editUserDialogFlag: false,
      // 编辑表单
      editUserForm: {
        account: '',
        nickname: '',
        mobilePhone: '',
        email: '',
        role: '',
        status: '1'
      },
      // 详情弹窗
      detailUserDialogFlag: false,
      // 详情表单
      detailForm: {}
    }
  },
  methods: {
    queryUserList() {
      this.$refs['search-form'].validate(valid => {
        if (valid) {
          this.getUserList()
        }
      })
    },
    resetBtn() {
      this.$refs['search-form'].resetFields()
      this.getUserList()
    },
    // 获取表格数据
    getUserList() {
      this.tableList = [{
        userId: 'ToguideDev001',
        account: 'admin123',
        nickname: 'HackerAC',
        mobilePhone: '17388888888',
        email: 'hackerac@163.com',
        createData: '2022-04-22',
        role: '001',
        status: '1'
      }]
    },
    // 打开新增弹窗
    addDialogOpen() {
      const that = this
      this.addUserDialogFlag = true
      this.$nextTick(() => {
        that.$refs['add-user-form'].resetFields()
      })
    },
    // 确认新增用户
    addUser() {
      this.$refs['add-user-form'].validate(valid => {
        if (valid) {
          this.addUserDialogFlag = false
        }
      })
    },
    // 打开编辑弹窗
    editDialogOpen(user) {
      this.editUserForm.account = user.account
      this.editUserForm.nickname = user.nickname
      this.editUserForm.email = user.email
      this.editUserForm.mobilePhone = user.mobilePhone
      this.editUserForm.role = user.role
      this.editUserForm.status = user.status

      this.editUserDialogFlag = true
    },
    // 确认编辑用户
    editUser() {
      this.$refs['edit-user-form'].validate(valid => {
        if (valid) {
          this.editUserDialogFlag = false
        }
      })
    },
    // 重置密码
    resetPwd(userId) {
      // 生成随机字符串
      const randomString = Math.random().toString(36).slice(-10)
      this.$prompt('', '重置密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: randomString,
        inputPattern: /^[A-Za-z0-9]{10,16}$/,
        inputErrorMessage: '预置密码必须是10-16位的英文字母和数字组合'
      }).then(({ value }) => {
        this.$message.success('重置密码成功！')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码'
        })
      })
    },
    // 获取用户详情
    getUserDetail(userId) {
      this.detailForm = {
        userId: 'ToguideDev001',
        account: 'admin123',
        nickname: 'HackerAC',
        mobilePhone: '17388888888',
        email: 'hackerac@163.com',
        createData: '2022-04-22',
        role: '开发者',
        status: '1'
      }
      this.detailUserDialogFlag = true
    },
    delUser(userId) {
      this.$confirm('是否确认永久删除此用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~$assets/css/common/search-area.scss";
@import "~$assets/css/common/table-area.scss";
.UserListContainer{
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
</style>
